import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Subject, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ToastHandlerService} from '../../shared/services/toast-handler.service';
import {TranslateService} from '@ngx-translate/core';
import {Metropolis} from '../../admin/modules/admin-global/models/metropolis';
import {ValetDocument, ValetDocuments} from '../../common-modules/common-valet/models/valet-document';
import {UserIdentityCheck} from './models/UserIdentityCheck';

declare const gtagRecruitement: (...args) => void;
declare const fbq: (...args) => void;

export interface User {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  metropolis: string;
  hasWGarage: boolean;
  wGarage: string;
}

export interface CandidateProfile {
  user: User;
  referralCode?: string;
  hasAutoentrepreneurStatus: boolean;
  hasInsurer: boolean;
  noTVA?: boolean;
  siret?: string;
  drivingLicensePoints: number;
  drivingLicenseAge: number;
  drivingLicenseType: string;
  sourcingType?: string;
  sourcingNote?: string;
  nationality: string;
  availableOnSaturday: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PvRecruitmentService {

  constructor(private http: HttpClient,
              private toastService: ToastHandlerService,
              private translateService: TranslateService) { }

  public isBlocked(firstName: string, lastName: string): Promise<any> {
    return this.http.post(`${environment.backendUrl}valet-blocks/is-blocked`, {
      firstName,
      lastName
    }).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(err => {
        return throwError(err);
      })
    ).toPromise();
  }

  public createCandidate(candidate: any): Promise<any> {
    return this.http.post(`${environment.backendUrl}candidates`, candidate).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(err => {
        if (err.error.errorCode === 'existing_user') {
          this.toastService.showErrorToasts(
            this.translateService.instant('recruitment.process.services.create_candidate_mail_error')
          );
          return Promise.reject('existing_user');
        } else if (err.error.errorCode === 'invalid_referral_code') {
          this.toastService.showErrorToasts(
            this.translateService.instant('recruitment.process.services.create_candidate_referral_code_error')
          );
        } else {
          this.toastService.showErrorToasts(
            this.translateService.instant('recruitment.process.services.create_candidate_error')
          );
        }
        return throwError(err);
      })
    ).toPromise();
  }

  public updateCandidateEligibility(eligibilityData): Promise<any> {
    return this.http.put(`${environment.backendUrl}candidates/eligibility`, eligibilityData).pipe(
      map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('recruitment.process.services.update_eligibility_success')
          );
          return res.data;
        },
        catchError(err => {
          this.toastService.showErrorToasts(
            this.translateService.instant('recruitment.process.services.update_eligibility_error')
          );
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        }))
    ).toPromise();
  }

  public getAllMetropolis(): Promise<Metropolis[]> {
    return this.http.get(
      `${environment.backendUrl}metropolises`
    )
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToasts(
            this.translateService.instant('admin.modules.global.services.metropolis.get_all_metropolis_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public startIdCheck(
    front: File | null,
    back: File | null,
    siret: string | null,
    idDocumentCheck: boolean,
    idVerificationCheck: boolean,
    siretCheck: boolean
  ) {
    const body = new FormData();
    body.append('idDocumentCheck', idDocumentCheck ? 'true' : 'false');
    body.append('idVerificationCheck', idVerificationCheck ? 'true' : 'false');
    body.append('siretCheck', siretCheck ? 'true' : 'false');
    if (front && back) {
      body.append('front', front);
      body.append('back', back);
    }
    if (siret) {
      body.append('siret', siret);
    }
    return this.http.post(`${environment.backendUrl}candidates/start-id-check`, body).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(err => {
        this.toastService.showErrorToastsResponse(err);
        return throwError(err);
      })
    ).toPromise();
  }

  public refreshIdVerificationCheckUrl(): Promise<UserIdentityCheck> {
    return this.http.post(`${environment.backendUrl}candidates/refresh-id-verification-check-url`, null).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(err => {
        this.toastService.showErrorToastsResponse(err);
        return throwError(err);
      })
    ).toPromise();
  }

  public getIdCheckStatus(): Promise<UserIdentityCheck> {
    return this.http.get(`${environment.backendUrl}candidates/id-check-status`)
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public getCandidateDocuments(): Promise<ValetDocuments> {
    return this.http.get(`${environment.backendUrl}valets/document`)
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public updateCandidateProfile(profileData: CandidateProfile): Promise<any> {
    return this.http.put(`${environment.backendUrl}candidates/profile`, profileData).pipe(
      map((res: any) => {
          this.toastService.showSuccessToast('Profile updated.');
          return res.data;
        },
        catchError(err => {
          this.toastService.showErrorToasts('Problem with profile update.');
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        }))
    ).toPromise();
  }

  public updateCandidateDocument(document: ValetDocument, valetId?: string): Promise<ValetDocuments> {
    const body = new FormData();
    body.append('valetDocument', document.url);
    if (document.expiryDate) {
      body.append('expiryDate', document.expiryDate);
    }
    if (valetId) {
      body.append('valetId', valetId);
    }
    return this.http.post(`${environment.backendUrl}valets/document/${document.documentType}`,
      body)
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public forgotPassword(email: string): Promise<any> {
    return this.http.post(`${environment.backendUrl}auth/forgot-password`, {
      email
    }).pipe(
      map((res: any) => {
        this.toastService.showSuccessToast(
          this.translateService.instant('auth.toast.forgot_password_success')
        );
        return res.data;
      }),
      catchError(err => {
        this.toastService.showErrorToasts(
          this.translateService.instant('auth.toast.forgot_password_error')
        );
        this.toastService.showErrorToastsResponse(err);
        return throwError(err);
      })
    ).toPromise();
  }

  public gaPageEvent(page: string) {
    const path = '/' + page;
    if (typeof gtagRecruitement !== 'undefined') {
      gtagRecruitement('event', 'page_view', {page_path: path});
    }
  }

  public fbEvent(eventName: string, fbc: string | undefined) {
    return this.http.post(`${environment.backendUrl}fb/event/${eventName}`, {
      fbc
    }).toPromise();
  }

  public getUpcomingWebinarSessions(): Promise<any> {
    return this.http.get(
      `${environment.backendUrl}candidates/webinar-sessions`
    )
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToasts(
            this.translateService.instant('recruitment.process.services.get_webinar_sessions_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public setWebinarSession(data: any): Promise<any> {
    return this.http.put(`${environment.backendUrl}candidates/webinar-sessions`, data).pipe(
      map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('recruitment.process.services.set_webinar_session_success')
          );
          return res.data;
        },
        catchError(err => {
          this.toastService.showErrorToasts(
            this.translateService.instant('recruitment.process.services.set_webinar_session_error')
          );
          return throwError(err);
        }))
    ).toPromise();
  }

  public getInterviewQuestions(): Promise<any> {
    return this.http.get(`${environment.backendUrl}candidates/video-questions`)
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToasts(
            this.translateService.instant('recruitment.process.interview.error_get_questions')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public getVideoPresignedUrl(contentType: string): Promise<any> {
    return this.http.get(`${environment.backendUrl}candidates/video-presigned-url`, {
      params: {
        contentType
      }
    })
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToasts(
            this.translateService.instant('recruitment.process.interview.error_get_presigned_url')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public updateInterviewVideo(questionsData) {
    return this.http.put(`${environment.backendUrl}candidates/video`,
      questionsData)
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('recruitment.process.interview.data_video_send')
          );
          return res.data;
        }),
        catchError(err => {
          this.toastService.showErrorToasts(
            this.translateService.instant('recruitment.process.interview.error_data_video')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public uploadInterviewVideo(presignedUrl: string, interviewBlob: Blob, contentType: string) {
    const progress = new Subject<number>();
    return {
      progress,
      promise: new Promise((resolve, reject) => {

        const req = new XMLHttpRequest();
        req.open('PUT', presignedUrl);

        req.upload.addEventListener('progress', (e) => {
          progress.next(Math.round((e.loaded / e.total) * 100));
        });

        req.addEventListener('load', () => {
          this.toastService.showSuccessToast(
            this.translateService.instant('recruitment.process.interview.video_send')
          );
          resolve();
        });

        req.addEventListener('error', () => {
          this.toastService.showErrorToasts(
            this.translateService.instant('recruitment.process.interview.video_not_send')
          );
          reject();
        });

        req.setRequestHeader('Content-Type', contentType);
        req.send(interviewBlob);
      })
    };
  }

}
