import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {ToastHandlerService} from '../../../../shared/services/toast-handler.service';
import {BackendFilteringObject} from '@pop-valet/smart-table-backend-wrapper';
import {BackendPaginationResponse} from '../../../../shared/models/backend-pagination-response';
import {Valet} from '../../admin-valet/models/valet';
import {environment} from '../../../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {ValetDocument, ValetDocuments} from '../../../../common-modules/common-valet/models/valet-document';
import {ExportCsvService} from '../../../../shared/services/export-csv.service';
import {UserIdentityCheck} from '../../../../pv-modules/pv-recruitment/models/UserIdentityCheck';
import * as moment from 'moment';
import {ValetBlock} from '../../../models/ValetBlock';
import {DateFilter} from '../../../../../submodule/model/date-filter.model';

@Injectable({
  providedIn: 'root'
})
export class AdminRecrutementService {

  constructor(private http: HttpClient,
              private translateService: TranslateService,
              private toastService: ToastHandlerService,
              private exportService: ExportCsvService,) {
  }

  private getQueryParams(
    date: DateFilter,
    tab: string | null,
    candidateId: string | null,
    paginate: boolean,
    archived?: boolean
  ) {
    const tabFilter: [string, string][] = [];
    switch (tab) {
      case 'documents':
        tabFilter.push(
          ['eligibilityStatus', 'valid'],
          ['documentsStatus', 'missing'],
          ['documentsStatus', 'invalid'],
          ['documentsStatus', 'pending'],
          ['sort', 'documentsStatus.asc']
        );
        break;
      case 'contracts':
        tabFilter.push(
          ['documentsStatus', 'valid'],
          ['contractStatus', 'missing'],
          ['contractStatus', 'invalid'],
          ['contractStatus', 'pending'],
        );
        break;
      case 'training':
        tabFilter.push(
          ['contractStatus', 'valid'],
          ['trainingStatus', 'missing'],
          ['trainingStatus', 'invalid'],
          ['trainingStatus', 'pending'],
        );
        break;
      default:
        break;
    }

    const params: [string, string][] = [
      ['archived', archived.toString()],
      ['paginate', paginate+'']
    ];
    if (candidateId) {
      params.push(['candidateId', candidateId]);
    }
    params.push(...tabFilter);
    if (date) {
      params.push(['date', JSON.stringify(date)]);
    }
    return new URLSearchParams(params);
  }

  public getCandidates(date: DateFilter,
                       filteringObj: BackendFilteringObject,
                       tab: string | null,
                       candidateId: string | null,
                       archived?: boolean
  ): Promise<BackendPaginationResponse<Valet>> {
    const queryParams = this.getQueryParams(date, tab, candidateId, true, archived);

    return this.http.get(`${environment.backendUrl}candidates?${queryParams.toString()}&${filteringObj.getBackendFiltering()}`)
      .pipe(
        map((res: any) => res),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  private exportCandidateParser(dataToExport: any[]): any[] {
    const formattedExport = [];
    dataToExport.forEach(row => {
      let sourcingValue: string;
      switch(row.sourcingType) {
        case 'wordOfMouth':
          sourcingValue = 'Bouche à oreille';
          break;
        case 'faceboook':
          sourcingValue = 'Facebook';
          break;
        case 'popvaletWebsite':
          sourcingValue = 'Site Pop Valet';
          break;
        case 'google':
          sourcingValue = 'Google';
          break;
        case 'assuranceAuto':
          sourcingValue = 'Assurance Auto';
          break;
        case 'other':
          sourcingValue = 'Autre';
          break;
        default:
          sourcingValue = row.sourcingType;
      }

      const formattedRow = {
        id: row.id,
        Prenom: row.user.firstName,
        Nom: row.user.lastName,
        Telephone: row.user.phone,
        Email: row.user.email,
        Metropole: row.user.metropolis?.name,
        Sourcing: sourcingValue,
        'Autre sourcing': row.sourcingNote,
      };

      const steps = [{
        key: 'eligibility',
        translation: 'Eligibilité'
      }, {
        key: 'videoInterview',
        translation: 'Entretien vidéo'
      }, {
        key: 'idCheck',
        translation: `Contrôle d'identité`
      }, {
        key: 'profile',
        translation: 'Profil'
      }, {
        key: 'documents',
        translation: 'Documents'
      }, {
        key: 'contract',
        translation: 'Contrat'
      }, {
        key: 'training',
        translation: 'Formation'
      }];

      steps.forEach(step => {
        const log = row.logs.find(log => log.type === step.key && log.status === 'valid');

        formattedRow[step.translation] = log ? moment(log.creationDate).format('DD/MM/YYYY HH:mm') : '';
      });

      formattedExport.push(formattedRow);
    });

    this.exportService.export(formattedExport);
    return formattedExport;
  }

  public exportCandidates(
    date: DateFilter,
    filteringObj: BackendFilteringObject,
    tab: string | null,
    candidateId: string | null,
    archived?: boolean
  ): Promise<any[]> {
    const queryParams = this.getQueryParams(date, tab, candidateId, false, archived);

    return this.http.get(
      `${environment.backendUrl}candidates?${queryParams.toString()}&${filteringObj.getBackendFiltering()}`, {
        params: {
          exportCSV: 'true'
        }
      }
    ).pipe(
      map((res: any) => {
        return this.exportCandidateParser(res.data);
      }),
      catchError(err => {
        this.toastService.showErrorToasts(
          this.translateService.instant('admin.modules.recruitment.services.candidate.candidate_export_error')
        );
        return throwError(err);
      })
    ).toPromise();
  }

  public updateArchivedStatus(candidateId: string, archived: boolean, archiveReason?: string): Promise<any> {
    return this.http.put(`${environment.backendUrl}candidates/archived/${candidateId}`, {
      archived,
      archiveReason
    })
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('admin.modules.recruitment.services.candidate.candidate_archived_success')
          );
          return res;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          this.toastService.showErrorToasts(
            this.translateService.instant('admin.modules.recruitment.services.candidate.candidate_archived_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public deleteCandidate(candidateId: string): Promise<any> {
    return this.http.delete(`${environment.backendUrl}candidates/${candidateId}`)
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('admin.modules.recruitment.services.candidate.candidate_deleted_success')
          );
          return res;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          this.toastService.showErrorToasts(
            this.translateService.instant('admin.modules.recruitment.services.candidate.candidate_deleted_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public sendReminder(candidateId: string): Promise<any> {
    return this.http.post(`${environment.backendUrl}candidates/send-reminder/${candidateId}`, {})
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('admin.modules.recruitment.services.candidate.candidate_send_reminder_success')
          );
          return res;
        }),
        catchError(err => {
          if (err.error?.errorCode === 'no_reminder_available') {
            this.toastService.showErrorToasts(
              this.translateService.instant('admin.modules.recruitment.services.candidate.no_reminder_available_error')
            );
          } else {
            this.toastService.showErrorToastsResponse(err);
          }
          this.toastService.showErrorToasts(
            this.translateService.instant('admin.modules.recruitment.services.candidate.candidate_send_reminder_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public getSendRemindersDate(status: string): Promise<any> {
    return this.http.get(`${environment.backendUrl}candidates/send-reminders-date/${status}`)
      .pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public sendReminders(status: string): Promise<any> {
    return this.http.post(`${environment.backendUrl}candidates/start-send-reminders/${status}`, {})
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('admin.modules.recruitment.services.candidate.candidate_start_send_reminders_success')
          );
          return res;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          this.toastService.showErrorToasts(
            this.translateService.instant('admin.modules.recruitment.services.candidate.candidate_start_send_reminders_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public updateTrainingStatus(candidateId: string, trainingStatus: string): Promise<any> {
    return this.http.put(`${environment.backendUrl}candidates/training/${candidateId}`, {
      trainingStatus
    })
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('admin.modules.recruitment.services.candidate.candidate_training_status_success')
          );
          return res;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          this.toastService.showErrorToasts(
            this.translateService.instant('modules.dashboard.service.candidate.candidate_training_status_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public getIdCheckStatus(valetId: string): Promise<UserIdentityCheck> {
    return this.http.get(`${environment.backendUrl}candidates/id-check-status/${valetId}`)
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public getValetDocuments(valetId: string): Promise<ValetDocuments> {
    return this.http.get(`${environment.backendUrl}admin/valets/document/${valetId}`)
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public loadValetDocument(document: ValetDocument, valetId?: string): Promise<ValetDocuments> {
    const body = new FormData();
    body.append('valetDocument', document.url);
    if (document.expiryDate) {
      body.append('expiryDate', document.expiryDate);
    }
    if (valetId) {
      body.append('valetId', valetId);
    }
    return this.http.post(`${environment.backendUrl}valets/document/${document.documentType}`,
      body)
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('admin.modules.valet.services.valet.valet_document_loaded_success')
          );
          return res.data;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public updateValetDocuments(valetId: string, documents: any[]): Promise<ValetDocuments> {
    return this.http.put(`${environment.backendUrl}admin/valets/document/${valetId}`, {
      documents
    })
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('admin.modules.valet.services.valet.valet_document_updated_success')
          );
          return res.data;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public getCandidateVideo(candidateId: string): Promise<any[]> {
    return this.http.get(
      `${environment.backendUrl}candidates/${candidateId}/video`
    ).pipe(
      map((res: any) => res.data),
      catchError(err => {
        this.toastService.showErrorToasts(
          this.translateService.instant('admin.modules.recruitment.services.candidate.candidate_get_video_interview_error')
        );
        return throwError(err);
      })
    ).toPromise();
  }

  public updateVideoStatus(candidateId: string, videoInterviewData: any): Promise<any> {
    const body = {...videoInterviewData};
    return this.http.put(`${environment.backendUrl}candidates/video/${candidateId}`, body)
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('admin.modules.recruitment.services.candidate.candidate_video_status_success')
          );
          return res;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          this.toastService.showErrorToasts(
            this.translateService.instant('admin.modules.recruitment.services.candidate.candidate_video_status_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public resendIdVerificationLink(valetId: string) {
    return this.http.put(`${environment.backendUrl}candidates/resend-id-verification-link/${valetId}`, {})
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('admin.modules.recruitment.services.candidate.resend_id_verification_link_success')
          );
          return res.data;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);

          return throwError(err);
        })
      ).toPromise();
  }

  public validateSiret(valetId: string) {
    return this.http.put(`${environment.backendUrl}candidates/validate-siret/${valetId}`, {})
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('admin.modules.recruitment.services.candidate.validate_siret_success')
          );
          return res.data;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public getValetBlock(valetId: string): Promise<ValetBlock | null> {
    return this.http.get(
      `${environment.backendUrl}admin/valet-blocks/by-valet-id/${valetId}`
    ).pipe(
      map((res: any) => res.data),
      catchError(err => {
        if (!err.error.errorCode) {
          this.toastService.showErrorToasts(
            this.translateService.instant('admin.modules.valet.services.valet.get_valet_block_error')
          );
        }
        return throwError(err);
      })
    ).toPromise();
  }

  public addToValetBlock(data: {
    dotfileIdDocumentCheckId?: string,
    firstName?: string,
    lastName?: string,
    birthDate?: string
  }): Promise<ValetBlock> {
    const body: any = {};
    if (data.firstName && data.lastName && data.birthDate) {
      body.firstName = data.firstName;
      body.lastName = data.lastName;
      body.birthDate = data.birthDate;
    } else if (data.dotfileIdDocumentCheckId) {
      body.dotfileIdDocumentCheckId = data.dotfileIdDocumentCheckId;
    } else {
      throw new Error('Wrong parameters');
    }
    return this.http.post(
      `${environment.backendUrl}admin/valet-blocks`, body
    ).pipe(
      map((res: any) => {
        this.toastService.showSuccessToast(
          this.translateService.instant('admin.modules.valet.services.valet.add_to_valet_block_success')
        );
        return res.data;
      }),
      catchError(err => {
        if (!err.error.errorCode) {
          this.toastService.showErrorToasts(
            this.translateService.instant('admin.modules.valet.services.valet.add_to_valet_block_error')
          );
        }
        return throwError(err);
      })
    ).toPromise();
  }
}
