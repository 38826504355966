import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastHandlerService } from '../../../../shared/services/toast-handler.service';
import { BackendFilteringObject } from '@pop-valet/smart-table-backend-wrapper';
import { BackendPaginationResponse } from '../../../../shared/models/backend-pagination-response';
import { environment } from '../../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Valet } from '../models/valet';
import { VALET_DOCUMENT_STATUS, VALET_DOCUMENT_TYPE, ValetDocuments } from '../../../../common-modules/common-valet/models/valet-document';
import { ExportCsvService } from '../../../../shared/services/export-csv.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AdminValetService {

  constructor(private http: HttpClient,
              private exportService: ExportCsvService,
              private translateService: TranslateService,
              private toastService: ToastHandlerService) {
  }

  public getValets(filteringObj: BackendFilteringObject): Promise<BackendPaginationResponse<Valet>> {
    return this.http.get(`${environment.backendUrl}admin/valets?paginate=true&${filteringObj.getBackendFiltering()}`)
      .pipe(
        map((res: any) => res),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public getValetFraudInfo(valetId: string): Promise<any> {
    const url = `${environment.backendUrl}admin/valets/get-fraud-info/${valetId}`;
    return this.http.get<any>(url).toPromise();
  }

  public getValetMissions(valetId: string): Promise<any> {
    const url = `${environment.backendUrl}admin/valets/get-missions-by-valet/${valetId}`;
    return this.http.get<any>(url).toPromise();
  }

  public exportValets(): Promise<any[]> {
    return this.http.get(`${environment.backendUrl}admin/valets?exportCSV=true`)
      .pipe(
        map((res: any) => {
          return this.exportValetParser(res.data);
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  private exportValetParser(dataToExport: any[]): any[] {
    const formattedExport = [];
    dataToExport.forEach(row => {

      let lastDeactivation = null;
      if (row.activeLogs.length > 0) {
        const logs = row.activeLogs.filter(l => l.active === false)
          .sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());
        lastDeactivation = logs[logs.length - 1];
      }

      let lastRemunerationBlock = null;
      let lastRemunerationUnblock = null;
      let lastExpensesBlock = null;
      let lastExpensesUnblock = null;
      if (row.valetInvoicingBlockLogs.length > 0) {
        lastRemunerationBlock = this.getLastOfFirstGroup(row.valetInvoicingBlockLogs, 'blockRemuneration', true);
        lastRemunerationUnblock = this.getLastOfFirstGroup(row.valetInvoicingBlockLogs, 'blockRemuneration', false);
        lastExpensesBlock = this.getLastOfFirstGroup(row.valetInvoicingBlockLogs, 'blockExpenses', true);
        lastExpensesUnblock = this.getLastOfFirstGroup(row.valetInvoicingBlockLogs, 'blockExpenses', false);
      }

      formattedExport.push({
        id: row.id,
        shortId: 'V' + row.id.substring(0, 8),
        previousShortId: row.previousId !== null ? ('V' + row.previousId.substring(0, 8)) : '',
        creationDate: moment(row.creationDate).format('DD/MM/YYYY'),
        title: row.title,
        firstName: row.firstName,
        lastName: row.lastName,
        accountName: row.firstName + ' ' + row.lastName,
        phone: row.phone,
        email: row.email,
        statusName: row.status,
        metropolis: row.metropolis ? row.metropolis.name : '',
        address: row.address,
        postalCode: row.postalCode,
        city: row.city,
        country: row.country,
        actif: row.active ? 'oui' : 'non',
        pret: row.ready !== null ? (row.ready ? 'oui' : 'non') : '',
        'Raison desactivation': row.disableReason ?? '',
        'Commentaire desactivation': row.disableComment ?? '',
        sncfStatus: row.sncfStatus ? 'oui' : 'non',
        birthDate: row.birthDate ? moment(row.birthDate).format('DD/MM/YYYY') : '',
        siret: row.siret,
        businessName: row.businessName,
        billingAddress: row.billingAddress,
        billingPostalCode: row.billingPostalCode,
        billingCity: row.billingCity,
        billingCountry: row.billingCountry,
        remunerationIBAN: row.remunerationIBAN,
        feesIBAN: row.feesIBAN,
        qualityRate: row.qualityRate > 0 ? Math.round(row.qualityRate * 1000) / 10 : row.qualityRate,
        levelOfExperience: row.levelOfExperience > 0 ? Math.round(row.levelOfExperience * 1000) / 10 : row.levelOfExperience,
        nomNiveauExperience: row.levelOfExperience >= 1 ? 'expert' : (row.levelOfExperience >= 0.5 ? 'intermédiaire' : 'débutant'),
        dateIntermediaire: row.experienceIntermediateDate ? moment(row.experienceIntermediateDate).format('DD/MM/YYYY') : '',
        dateExpert: row.experienceExpertDate ? moment(row.experienceExpertDate).format('DD/MM/YYYY') : '',
        derniereDesactivation: lastDeactivation ? moment(lastDeactivation.date).format('DD/MM/YYYY HH:mm:ss') : '',
        premiereMission: row.firstMissionDate ? moment(row.firstMissionDate).format('DD/MM/YYYY') : '',
        derniereMission: row.lastMissionDate ? moment(row.lasttMissionDate).format('DD/MM/YYYY') : '',
        lotsProposes: row.proposedBatchesCount,
        lotsAcceptes: row.acceptedBatchesCount,
        lotsRefuses: row.declinedBatchesCount,
        lotsDesassignes: row.unassignedBatchesCount,
        pourcentageLotsAcceptes: row.acceptedBatchesPercentage,
        pourcentageLotsRefuses: row.declinedBatchesPercentage,
        kaze: row.enableLastbill ? 'oui' : 'non',
        'Dernier blocage retributions': lastRemunerationBlock ? moment(lastRemunerationBlock.date).format('DD/MM/YYYY HH:mm:ss') : '',
        'Dernier deblocage retributions': lastRemunerationUnblock ? moment(lastRemunerationUnblock.date).format('DD/MM/YYYY HH:mm:ss') : '',
        'Dernier blocage frais': lastExpensesBlock ? moment(lastExpensesBlock.date).format('DD/MM/YYYY HH:mm:ss') : '',
        'Dernier deblocage frais': lastExpensesUnblock ? moment(lastExpensesUnblock.date).format('DD/MM/YYYY HH:mm:ss') : ''
      });
    });
    this.exportService.export(formattedExport);
    return formattedExport;
  }

  public getLastOfFirstGroup<T>(array: T[], key: keyof T, value: any) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] === value) { // First occurrence of "value"
        for (let j = i; j < array.length; j++) {
          if (!array[j + 1] || array[j + 1][key] !== value) { // Next occurrence doesn't exist or is different
            return array[j];
          }
        }
      }
    }

    return null;
  }

  public getValet(valetId: string): Promise<Valet> {
    return this.http.get(`${environment.backendUrl}admin/valets/${valetId}`)
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public createValet(valet: Valet): Promise<Valet> {
    return this.http.post(`${environment.backendUrl}admin/valets`,
      valet)
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('admin.modules.valet.services.valet.valet_created_success')
          );
          return res.data;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public updateValet(valetId: string, valet: Valet): Promise<Valet> {
    return this.http.put(`${environment.backendUrl}admin/valets/${valetId}`,
      valet)
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('admin.modules.valet.services.valet.valet_updated_success')
          );
          return res.data;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public getValetDocuments(valetId: string): Promise<ValetDocuments> {
    return this.http.get(`${environment.backendUrl}admin/valets/document/${valetId}`)
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public updateValetDocument(
    valetId: string,
    documentType: VALET_DOCUMENT_TYPE,
    validationState: VALET_DOCUMENT_STATUS,
    expiryDate: string | undefined
  ): Promise<ValetDocuments> {
    const body: any = {
      documents: [{
        documentType,
        status: validationState
      }]
    };
    if (expiryDate) {
      body.documents[0].expiryDate = expiryDate;
    }
    return this.http.put(`${environment.backendUrl}admin/valets/document/${valetId}`, body)
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
          this.translateService.instant('admin.modules.valet.services.valet.valet_document_updated_success')
        );
          return res.data;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public getActiveValets(onlyReady: boolean, availableOn?: string, getDoneMissionsCount?: boolean): Promise<any> {
    const params: any = {
      active: true
    };
    if (onlyReady) {
      params.ready = onlyReady;
    }
    if (availableOn) {
      params.availableOn = availableOn;
    }
    if (getDoneMissionsCount) {
      params.getDoneMissionsCount = getDoneMissionsCount;
    }
    return this.http.get(`${environment.backendUrl}admin/valets`, {
      params
    })
      .pipe(
        map((res: any) => res.data),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public getValetPositiveFeedbacks(valetId: string, filteringObj: BackendFilteringObject): Promise<BackendPaginationResponse<any>> {
    return this.http.get(`${environment.backendUrl}missions/customer-feedback/by-valet/${valetId}?paginate=true&${filteringObj.getBackendFiltering()}`)
      .pipe(
        map((res: any) => res),
        catchError(err => {
          this.toastService.showErrorToasts(
            this.translateService.instant('modules.mission.service.get_valet_positive_feedbacks_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public generateContract(valetId: string): Promise<any> {
    return this.http.post(`${environment.backendUrl}admin/valets/generate-contract/${valetId}`, {})
      .pipe(
        map((res: any) => {
          this.toastService.showSuccessToast(
            this.translateService.instant('admin.modules.valet.services.valet.valet_contract_generated_success')
          );
          return res.data;
        }),
        catchError(err => {
          this.toastService.showErrorToastsResponse(err);
          return throwError(err);
        })
      ).toPromise();
  }

  public getValetAnomalies(valetId: string, filteringObj: BackendFilteringObject): Promise<BackendPaginationResponse<any>> {
    return this.http.get(`${environment.backendUrl}admin/valets/${valetId}/anomalies?paginate=true&${filteringObj.getBackendFiltering()}`)
      .pipe(
        map((res: any) => res),
        catchError(err => {
          this.toastService.showErrorToasts(
            this.translateService.instant('modules.mission.service.get_mission_anomalies_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }

  public getValetInvoices(valetId: string, filteringObj: BackendFilteringObject): Promise<BackendPaginationResponse<any>> {
    return this.http.get(`${environment.backendUrl}valets/${valetId}/invoices?paginate=true&${filteringObj.getBackendFiltering()}`)
      .pipe(
        map((res: any) => res),
        catchError(err => {
          this.toastService.showErrorToasts(
            this.translateService.instant('modules.valet.valet_invoices.service.get_invoices_error')
          );
          return throwError(err);
        })
      ).toPromise();
  }
}
